<div class="filter-panel">
  <locumsnest-filter-panel
    [filterGroups]="filterGroups$ | async"
    [filterGroupHasPreferredProfession]="filterGroupHasPreferredProfession$ | async"
    [selectedFilterGroup]="selectedFilterGroup$ | async"
    [selectedProfession]="selectedProfession$ | async"
    [selectedWard]="selectedWard$ | async"
    [showDepartmentSection]="showDepartmentSection$ | async"
    [canSelectAll]="canSelectAll$ | async"
    [selectAllInProgress]="selectAllInProgress$ | async"
    [filterAlert]="filterAlert$ | async"
    (closeModal)="onToggleFiltersPanel()"
    (saveModal)="onToggleFiltersPanel()"
    (filterCategorySelected)="onFilterCategorySelected($event)"
    (updateSelectedFilter)="onUpdateSelectedFilter($event)"
    (updateSelectedCategory)="onUpdateSelectedCategory($event)"
    (selectAllClicked)="onSelectAllClicked($event)"
    (optionSelected)="onOptionSelected($event)"
    (showHideDepartmentSection)="onUpdateShowDepartmentSection()"
    (selectProfessionDepartments)="onSelectProfessionDepartments($event)"
    (unselectProfessionDepartments)="onUnselectProfessionDepartments($event)"
  >
    <div
      *ngIf="
        (selectedFilterGroup$ | async) === 'Profession' &&
        (hasSelectedProfessions$ | async) &&
        (showDepartmentSection$ | async) === false
      "
    >
      <span *ngIf="(selectedFilterGroup$ | async) === 'Profession'" class="next-step">
        Next: Select your Department
      </span>
      <locumsnest-button
        [buttonType]="'go'"
        [size]="'medium'"
        [hasBoldFont]="true"
        (click)="onUpdateShowDepartmentSection()"
      >
        NEXT
      </locumsnest-button>
    </div>
    <div
      *ngIf="(selectedFilterGroup$ | async) === 'Profession' && (showDepartmentSection$ | async)"
    >
      <locumsnest-button
        [buttonType]="'go'"
        [size]="'medium'"
        [hasBoldFont]="true"
        (click)="onUpdateShowDepartmentSection()"
      >
        BACK
      </locumsnest-button>
    </div>
    <locumsnest-button
      [buttonType]="'onboarding-next'"
      [hasBoldFont]="true"
      [size]="'medium'"
      (click)="onToggleFiltersPanel()"
    >
      Close
    </locumsnest-button>
  </locumsnest-filter-panel>
</div>
