import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredProfessionSpecialtyEntity'>(
  'PreferredProfessionSpecialtyEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredProfessionSpecialtyTempSignal extends actionableFactory.create<
  'Add Selected Preferred Sub Specialty Temp Signal',
  { id: number }
>('Add Selected Preferred Sub Specialty Temp Signal') {}

export class RemoveSelectedPreferredProfessionSpecialtyTempSignal extends actionableFactory.create<
  'Remove Selected Preferred Sub Specialty Temp Signal',
  { id: number }
>('Remove Selected Preferred Sub Specialty Temp Signal') {}

export type PreferredProfessionSpecialtySignals = InitializeCollectionSignal;
