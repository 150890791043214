import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  IFilterCategoryPayload,
  IFilterGroup,
  IFilterSelectAllPayload,
  IOptionPayload,
} from '@locumsnest/components/src/lib/interfaces/filter';

import {
  ShowHideDepartmentSectionSignal,
  UpdateSelectedCategorySignal,
  UpdateSelectedFilterGroupSignal,
} from '../../+state/filter-ui/filter-ui.signals';
import { FilterService } from '../../+state/filter.service';
import { ToggleFiltersPanelSignal } from '../../../core/+state/ui/ui.signals';
import { IWardEntity } from '../../../interfaces/api/ward-entity';
import {
  AddSelectedPreferredProfessionSpecialtyTempSignal,
  RemoveSelectedPreferredProfessionSpecialtyTempSignal,
} from '../../../preferred-profession-specialty/+state/temp/preferred-profession-specialty.signals';
import { PreferredProfessionService } from '../../../preferred-profession/+state/preferred-profession.service';
import {
  AddSelectedPreferredRosterTempSignal,
  ClearAllPreferredRostersForSelectedWardTempSignal,
  ClearAllPreferredRostersTempSignal,
  RemoveSelectedPreferredRosterTempSignal,
  SelectAllPreferredRostersForSelectedWardTempSignal,
  SelectAllPreferredRostersTempSignal,
} from '../../../preferred-roster/+state/temp/preferred-roster.signals';
import {
  AddSelectedPreferredSiteTempSignal,
  ClearAllPreferredSitesTempSignal,
  RemoveSelectedPreferredSiteTempSignal,
  SelectAllPreferredSitesTempSignal,
} from '../../../preferred-site/+state/temp/preferred-site.signals';
import {
  AddSelectedPreferredSpecialtyCategoryTempSignal,
  ClearAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal,
  ClearAllPreferredSpecialtyCategoriesTempSignal,
  RemoveSelectedPreferredSpecialtyCategoryTempSignal,
  SelectAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal,
  SelectAllPreferredSpecialtyCategoriesTempSignal,
} from '../../../preferred-specialty-category/+state/temp/preferred-specialty-category-temp.signals';
import { IProfessionEntity } from './../../../interfaces/api/profession-entity';

@Component({
  selector: 'locumsnest-common-filter-panel',
  templateUrl: './common-filter-panel.component.html',
  styleUrls: ['./common-filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonFilterPanelComponent {
  public filterGroups$: Observable<IFilterGroup[]>;
  public filterGroupHasPreferredProfession$: Observable<number[]>;
  public selectedFilterGroup$: Observable<string>;
  public selectedProfession$: Observable<IProfessionEntity>;
  public selectedWard$: Observable<IWardEntity>;
  public showDepartmentSection$: Observable<boolean>;

  public canSelectAll$: Observable<boolean>;
  public selectAllInProgress$: Observable<boolean>;

  public filterAlert$: Observable<string>;
  public hasSelectedProfessions$: Observable<boolean>;
  public hasSelectedWards$: Observable<boolean>;

  public showFiltersPanel = false;
  public showProductPanel = false;

  constructor(
    private store: Store,
    private filterService: FilterService,
    private preferredProfessionService: PreferredProfessionService
  ) {
    this.filterGroups$ = this.filterService.getFilterGroups();
    this.filterGroupHasPreferredProfession$ =
      this.preferredProfessionService.hasPreferredProfessions();
    this.selectedFilterGroup$ = this.filterService.getSelectedFilterGroup();
    this.selectedProfession$ = this.filterService.getSelectedProfession();
    this.selectedWard$ = this.filterService.getSelectedWard();
    this.canSelectAll$ = this.filterService.canSelectAll();
    this.selectAllInProgress$ = this.filterService.getSelectAllInProgress();
    this.filterAlert$ = this.filterService.getFilterAlert();
    this.showDepartmentSection$ = this.filterService.getShowDepartmentSection();
    this.hasSelectedProfessions$ = this.filterService.hasSelectedProfessions();
    this.hasSelectedWards$ = this.filterService.hasSelectedWards();
  }

  closeProductPanel() {
    this.showProductPanel = false;
  }

  onUpdateShowDepartmentSection() {
    return this.store.dispatch(new ShowHideDepartmentSectionSignal({}));
  }

  onSelectProfessionDepartments(selectedFilterGroup: string) {
    if (selectedFilterGroup === 'Profession') {
      return this.store.dispatch(
        new SelectAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal({})
      );
    } else if (selectedFilterGroup === 'Ward') {
      return this.store.dispatch(new SelectAllPreferredRostersForSelectedWardTempSignal({}));
    }
  }

  onUnselectProfessionDepartments(selectedFilterGroup: string) {
    if (selectedFilterGroup === 'Profession') {
      return this.store.dispatch(
        new ClearAllPreferredSpecialtyCategoriesForSelectedProfessionTempSignal({})
      );
    } else if (selectedFilterGroup === 'Ward') {
      return this.store.dispatch(new ClearAllPreferredRostersForSelectedWardTempSignal({}));
    }
  }

  onToggleFiltersPanel() {
    this.store.dispatch(new ToggleFiltersPanelSignal({}));
  }

  onOptionSelected(payload: IOptionPayload) {
    if (payload.filterGroupName === 'Site') {
      return this.toggleSite(payload);
    }
    if (payload.filterGroupName === 'Profession') {
      return this.toggleProfession(payload);
    }

    if (payload.filterGroupName === 'Ward') {
      return this.toggleRoster(payload);
    }
  }

  toggleSite(payload: IOptionPayload) {
    if (!payload.selected) {
      return this.store.dispatch(new AddSelectedPreferredSiteTempSignal({ id: payload.id }));
    }
    return this.store.dispatch(new RemoveSelectedPreferredSiteTempSignal({ id: payload.id }));
  }

  toggleRoster(payload: IOptionPayload) {
    if (!payload.selected) {
      return this.store.dispatch(
        new AddSelectedPreferredRosterTempSignal({ id: payload.id, wardId: payload.parentId })
      );
    }
    return this.store.dispatch(
      new RemoveSelectedPreferredRosterTempSignal({ id: payload.id, wardId: payload.parentId })
    );
  }

  toggleProfession(payload: IOptionPayload) {
    if (payload.filterOptionName === 'Department access') {
      return this.toggleDepartment(payload);
    }
    if (payload.filterOptionName === 'Sub-Department access') {
      return this.toggleSubDepartment(payload);
    }
  }

  toggleDepartment(payload: IOptionPayload) {
    if (!payload.selected) {
      return this.store.dispatch(
        new AddSelectedPreferredSpecialtyCategoryTempSignal({
          id: payload.id,
          professionId: payload.parentId,
        })
      );
    }
    return this.store.dispatch(
      new RemoveSelectedPreferredSpecialtyCategoryTempSignal({
        id: payload.id,
        professionId: payload.parentId,
      })
    );
  }

  toggleSubDepartment(payload: IOptionPayload) {
    if (!payload.selected) {
      return this.store.dispatch(
        new AddSelectedPreferredProfessionSpecialtyTempSignal({ id: payload.id })
      );
    }
    return this.store.dispatch(
      new RemoveSelectedPreferredProfessionSpecialtyTempSignal({ id: payload.id })
    );
  }

  onFilterCategorySelected(payload: IFilterCategoryPayload) {
    this.filterService.dispatchToggleSignal(payload).subscribe();
  }

  onUpdateSelectedFilter(filterGroupName: string) {
    return this.store.dispatch(
      new UpdateSelectedFilterGroupSignal({ selectedFilterGroup: filterGroupName })
    );
  }

  onUpdateSelectedCategory(categoryId: number) {
    return this.store.dispatch(
      new UpdateSelectedCategorySignal({ selectedCategoryId: categoryId })
    );
  }

  onSelectAllClicked(filterSelectAllPayload: IFilterSelectAllPayload) {
    if (filterSelectAllPayload.filterGroup === 'Site') {
      return this.toggleSelectAllSites(filterSelectAllPayload);
    }
    if (filterSelectAllPayload.filterGroup === 'Profession') {
      return this.toggleSelectAllProfessions(filterSelectAllPayload);
    }

    if (filterSelectAllPayload.filterGroup === 'Ward') {
      return this.toggleSelectAllWards(filterSelectAllPayload);
    }
  }

  toggleSelectAllSites(filterSelectAllPayload: IFilterSelectAllPayload) {
    if (filterSelectAllPayload.selected) {
      return this.store.dispatch(new SelectAllPreferredSitesTempSignal({}));
    }
    return this.store.dispatch(new ClearAllPreferredSitesTempSignal({}));
  }

  toggleSelectAllRosters(filterSelectAllPayload: IFilterSelectAllPayload) {
    if (filterSelectAllPayload.selected) {
      return this.store.dispatch(new SelectAllPreferredRostersTempSignal({}));
    }
    return this.store.dispatch(new ClearAllPreferredRostersTempSignal({}));
  }

  toggleSelectAllProfessions(filterSelectAllPayload: IFilterSelectAllPayload) {
    if (filterSelectAllPayload.showDepartmentSection) {
      if (filterSelectAllPayload.selected) {
        return this.store.dispatch(new SelectAllPreferredSpecialtyCategoriesTempSignal({}));
      }
      return this.store.dispatch(new ClearAllPreferredSpecialtyCategoriesTempSignal({}));
    }
    return this.filterService
      .dispatchToggleAllProfessionsSignal(filterSelectAllPayload)
      .subscribe();
  }

  toggleSelectAllWards(filterSelectAllPayload: IFilterSelectAllPayload) {
    if (filterSelectAllPayload.showDepartmentSection) {
      if (filterSelectAllPayload.selected) {
        return this.store.dispatch(new SelectAllPreferredRostersTempSignal({}));
      }
      return this.store.dispatch(new ClearAllPreferredRostersTempSignal({}));
    }
    return this.filterService.dispatchToggleAllWardsSignal(filterSelectAllPayload).subscribe();
  }
}
