import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory =
  SignalableFactory.forFeature<'PreferredSiteEntity'>('PreferredSiteEntity');

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredSiteTempSignal extends actionableFactory.create<
  'Add Selected Preferred Site Temp Signal',
  { id: number }
>('Add Selected Preferred Site Temp Signal') {}

export class RemoveSelectedPreferredSiteTempSignal extends actionableFactory.create<
  'Remove Selected Preferred Site Temp Signal',
  { id: number }
>('Remove Selected Preferred Site Temp Signal') {}

export class SelectAllPreferredSitesTempSignal extends actionableFactory.create<
  'Select All Preferred Sites Temp Signal',
  Record<string, never>
>('Select All Preferred Sites Temp Signal') {}

export class ClearAllPreferredSitesTempSignal extends actionableFactory.create<
  'Clear All Preferred Sites Temp Signal',
  Record<string, never>
>('Clear All Preferred Sites Temp Signal') {}

export type PreferredSiteSignals = InitializeCollectionSignal;
